/* Custom styles for the slick carousel dots */

.slick-dots {
  list-style: none; /* Remove default list bullets */
  display: flex;
  justify-content: center; /* Center the dots horizontally */
  margin: 20px 0; /* Adjust the margin as needed */
  padding: 0; /* Remove any default padding */
}

  
  .slick-dots li {
    margin: 0px 6px; /* Adjust spacing between dots */
  }
  
  .slick-dots li button {
    width: 15px; /* Adjust the size of the dot */
    height: 15px;
    border-radius: 50%; /* Make the dot circular */
    /* background-color: #bbb;  */
    /* Set the default dot color */
    
    border: none;
    cursor: pointer;
    opacity: 0.7; /* Set default opacity */
    transition: opacity 0.3s ease; /* Add a smooth transition effect */
  
  }
  
  .slick-dots li.slick-active button {
    width: 18px; /* Adjust the size of the dot */
    height: 18px;
    background-color: #0359AB;
    margin-top: 0px;
    margin-left: 1px;
    /* background-color: #333;  */
    /* Change color of the active dot */
    opacity: 1; /* Make the active dot fully opaque */
  }
  